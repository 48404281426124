import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const year = new Date().getFullYear();

export default function Footer({ data }) {
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setPageLoader(true);
        }, 100);
    }, []);

    return (
        <div className={`${styles.footer} ${pageLoader ? styles.pageReady : ''}`}>
            <div className={styles.content}>
                {data?.poweredByImages && data?.poweredByImages?.map((img, index) => (
                    <figure key={index}>
                        <img src={img.url} alt={img.alternativeText} />
                    </figure>
                ))}

                {data?.copyRightYear && <p>{data?.copyRightYear}</p>}
                {data?.brandLogo && <figure className={styles.eie}>
                    <img src={data?.brandLogo?.url} alt={data?.brandLogo?.alternativeText} />
                </figure>}
            </div>
        </div>
    );
}
