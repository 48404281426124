import React, { useState, useEffect } from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import '@/styles/_var.scss';
import "@/styles/style.scss";
import "@/styles/cookiebot.scss";
import Header from "@/components/header";
import Head from "next/head";
import Footer from "@/components/footer";
import  secureLocalStorage  from  "react-secure-storage";
import Script from "next/script";
import TagManager from 'react-gtm-module';

export default function App({ Component, pageProps }) {
    const size = useWindowSize();
    const [langData, setLangData] = useState(null);
    const [data, setData] = useState({});
    const [header, setHeader] = useState({});
    const [footer, setFooter] = useState({});

    const dataCommon = () => {
        const lang = secureLocalStorage.getItem("lang");
        (async() => {
            try {
                const resData = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=*&locale=${lang}`).then((res) => res.json());
                const data = resData.data;
                setData(data);
                setHeader({
                    logo: data.logo,
                    langMenu: data.langMenu,
                    menu: data.menu
                });
                setFooter({
                    copyRightYear: data.copyRightYear,
                    brandLogo: data.brandLogo,
                    poweredByImages: data.poweredByImages
                });
            } catch(err) {
                console.log(err)
            }
        })();
    }
    

    const dataLang = () => {
        (async () => {
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/i18n/locales`).then((res) => res.json());
                setLangData(data);
            } catch (err) {
                console.log(err);
            }
        })();
    };

    useEffect(() => {
        dataCommon();
        dataLang();
        const tagManagerArgs = {
            gtmId: 'G-QWVXHWQFB7', // Replace with your GTM ID
          };
          TagManager.initialize(tagManagerArgs);
          
    }, []);

    useEffect(() => {
        const main = document.querySelector('.main');
        main.style.minHeight = `${size.height}px`;
    }, [size]);

    return (
        <>
            <Head>
                <title>{data?.head?.title}</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                />
                <meta
                    name="description"
                    content={data?.head?.metaDescription}
                />
                <meta name="keywords" content={data?.head?.metaKeywords} />
                <link rel="icon" type="image/png" href="/favicon.png" />
            </Head>
            <Script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="41198843-2856-49ba-b50b-d812d7b22507" data-blockingmode="manual" type="text/javascript"></Script>
            <Script id="CookieDeclaration" src="https://consent.cookiebot.com/41198843-2856-49ba-b50b-d812d7b22507/cd.js" async />
            <div className="main">
                <Header data={header} langData={langData} />
                <main>
                    <Component {...pageProps} />
                </main>
                <Footer  data={footer} />
            </div>
        </>
    );
}


function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // only execute all the code below in client side
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
       
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }