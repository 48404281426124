import React, { useState, useEffect } from "react";
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import Link from 'next/link';
import secureLocalStorage from "react-secure-storage";
import BtnCircleArrow from '../../public/icons/btn-circle-arrow.svg';
import Close from '../../public/icons/icon-x.svg';
import Lang from '../../public/icons/icon-lang.svg';
import Back from '../../public/icons/icon-left-arrow.svg';

export default function Header({ data, langData, className }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuLang, setMenuLang] = useState(false);
    const router = useRouter();

    const setLangInit = () => {
        const checkLang = secureLocalStorage.getItem("lang");
        if (checkLang == null || checkLang == '') {
            secureLocalStorage.setItem("lang", 'zh-Hant-TW');
        }
        // console.log(checkLang);
    }

    // const goBack = () => {
    //     if (window.history.length > 1) {
    //         window.history.back(); // Navigate to the previous page
    //     } else {
    //         window.location.href = '/'; // Redirect to home if no history exists
    //     }
    // };


    const handleOpen = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    };
    const handleLangOpen = () => {
        setMenuLang(!menuLang);
    };



    const setLangFn = (e) => {
        secureLocalStorage.setItem("lang", e);
        router.push('/');
        setTimeout(() => {
            router.reload();
        }, 500);
    }

    useEffect(() => {
        setLangInit();
    }, []);
    return (
        <header className={`${styles.header} ${className || ''}`}>
            <div className={styles.stickybar}>
                {(router.pathname == '/') ?
                    <button className={`${styles.langBtn} ${menuLang ? styles.open : ''}`} onClick={() => { handleLangOpen() }}>
                        <Lang />
                    </button>
                    : <div />
                }
                {/* <button onClick={goBack} className={styles.backBtn}>
                        <Back />
                    </button> */}
                <button className={`${styles.navBtn} ${menuOpen ? styles.open : ''}`} onClick={handleOpen}><i></i><i></i><i></i></button>

                <div className={`${styles.navWrap} ${styles.lang} ${menuLang ? styles.open : ''}`}>
                    <div>
                        <button className={styles.closeBtn} onClick={() => { handleLangOpen(false) }}><Close /></button>
                        {/* <i><Lang /></i> */}
                        {langData && <nav className={styles.langNav}>
                            {langData?.map((l, index) => (
                                <button key={index} className={styles.link} data-lang={l.code} onClick={(e) => setLangFn(e.target.getAttribute('data-lang'))}>{l.name}<BtnCircleArrow /></button>
                            ))}
                        </nav>}
                    </div>
                </div>

                <div className={`${styles.navWrap} ${styles.menu} ${menuOpen ? styles.open : ''}`}>
                    <div>
                        {data.logo && <figure className={styles.logoNav}>
                            <img src={data?.logo?.url} alt={data?.logo?.alternativeText} />
                        </figure>}
                        {data?.menu && (
                            <nav>
                                {data?.menu?.map((link, index) => (
                                    <Link
                                        key={index}
                                        href={`/${link.url.replace(/^\/+/, '')}`}
                                        style={{ '--target-color': link.theme || '#F3D682' }}
                                        onClick={() => {
                                            setMenuOpen(false); // Close menu
                                            document.body.classList.remove('menu-open'); // Remove class from body
                                        }}
                                    >
                                        <span>0{index + 1} - <br />{link.text}</span>
                                        <BtnCircleArrow />
                                    </Link>
                                ))}
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}

